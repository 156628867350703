<template>
  <TTView>
    <VRow>
      <VCol cols="1">
        <TTBtn
          color="tt-light-mono-100"
          icon
          :to="{
            name : Names.R_LEARNING_COURSE_V2_CONTENT_CREATE
          }"
        >
          <VIcon>$prev</VIcon>
        </TTBtn>
      </VCol>
      <VCol>
        <h1>{{ $t('create_content.header') }}</h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <h3>{{ $t('edit_html_content.sub_header') }}</h3>
        <h4 v-if="content">
          {{ content.id }}
        </h4>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VForm
          v-if="content"
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <VRow>
            <VCol cols="12">
              <TTTextField
                v-model="content.title"
                :label="$t('create_content.title')"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTTextField
                v-model="content.description"
                :label="$t('create_content.description')"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTTextarea
                v-model="content.body"
                :label="$t('create_content.body')"
                :rules="[rules.required]"
                :maxlength="false"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="12">
              <TTBtn
                :disabled="!isValid || loading"
                @click="submit"
              >
                {{ $t('edit_html_content.submit') }}
              </TTBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { required } from '@front.backoffice/common';

export default {
  name: 'EditHtmlContent',
  inject: ['Names'],
  data() {
    return {
      loading: false,
      content: null,
      isValid: false,
    };
  },
  computed: {
    rules() {
      return {
        required: required(),
      };
    },
    contentId() {
      return this.$route.params.contentId;
    },
  },
  async created() {
    this.content = await this.fetchContent(this.contentId);
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        await this.updateContent();
      }
    },
    async fetchContent(id) {
      try {
        this.loading = true;
        const { content } = await this.$di.api.MultiContent.sandboxGet({ id });
        return content;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      } finally {
        this.loading = false;
      }
    },

    async updateContent() {
      try {
        this.loading = true;
        const id = await this.$di.api.MultiContent.sandboxUpdate({
          id: this.$route.params.contentId,
          content: {
            description: this.content.description,
            title: this.content.title,
            body: this.content.body,
          },
        });
        this.$di.notify.snackSuccess(this.$t('edit_html_content.result_success'));
        return id;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
